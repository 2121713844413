<template>
  <div v-if="park">
    <v-img
      contain
      class="grey darken-4 banner-pin"
      height="450"
      :src="loadImg(park.URLFOTO)"
    >
      <v-container class="h-100 d-flex flex-column justify-content-between">
        <div></div>
        <div class="title-pin-container">
          <h5 class="title-pin readable">{{ park.DESCRIPCION }}</h5>

          <p class="subtitle-pin readable">{{ park.FRASE }}</p>
        </div>
      </v-container>
    </v-img>
    <v-container>
      <v-row class="pt-4">
        <v-col v-if="park.HISTORIA && park.HISTORIA.length > 4" cols="12" lg="12" md="12" sm="12">
          <div class="shadow-col">
            <!-- <TitleSection :title="labels.history[lang]" noLogo smallText class="mb-4"></TitleSection> -->
            <p v-html="park.HISTORIA" class="mb-0 readable"></p>
          </div>
        </v-col>
      </v-row>
      <v-row class="py-0 mb-2">
        <v-col cols="12" lg="12" md="12" sm="12">
          <v-row class="justify-content-center">

            <v-col v-if="groups && groups.length > 0" cols="12" lg="6" md="6" sm="12">
              <div class="shadow-col">
                <TitleSection
                  :title="labels.information[lang]"
                  noLogo
                  smallText
                  class="mb-3 fw-600 readable"
                ></TitleSection>
                <v-card
                  elevation="0"
                  max-width="400"
                  class="card-group-information mx-auto readable"
                  v-for="(item, idx) in groups"
                  :key="'G1' + idx"
                >
                  <v-card-text>
                    <div class="d-flex align-items-center">
                      <img
                        class="img-group"
                        :src="loadImg(item.URLFOTO)"
                        :alt="item.DESCRIPCION"
                      />
                      <h3 class="ml-2 title-group">{{ item.DESCRIPCION }}</h3>
                    </div>
                    <v-list-item
                      two-line
                      v-for="(detail, i) in getGroupsDetail(
                        item.IDGRUPOINFORMATIVOPARQUE
                      )"
                      :key="'G11' + i"
                    >
                      <v-list-item-content class="item-content">
                        <v-list-item-title class="title-detail-group">
                          {{ detail.DESCRIPCIONCORTA }}
                        </v-list-item-title>
                        <v-list-item-subtitle
                          v-html="detail.DESCRIPCIONALARGA"
                        ></v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                  </v-card-text>
                </v-card>
              </div>
            </v-col>

            <v-col cols="12" lg="6" md="6" sm="12">
              <div class="shadow-col">
                <TitleSection
                  :title="labels.contact[lang]"
                  noLogo
                  smallText
                  class="mb-3 fw-600 readable"
                ></TitleSection>

                <v-card
                  elevation="0"
                  max-width="400"
                  class="card-group-information mx-auto readable"
                  v-for="(item, idx) in getContactList()"
                  :key="'G2' + idx"
                >
                  <v-card-text>
                    <div class="d-flex align-items-center">
                      <img
                        class="img-group"
                        :src="loadImg(item.URLFOTO)"
                        :alt="item.DESCRIPCION"
                      />
                      <h3 class="ml-2 title-group readable">
                        {{ item.DESCRIPCION[lang] }}
                      </h3>
                    </div>
                    <template v-for="(detail, i) in item.ITEMS">
                      <v-list-item v-if="detail" two-line :key="'G22' + i" class="readable">
                        <v-list-item-content class="item-content">
                          <v-list-item-subtitle
                            v-html="detail"
                          ></v-list-item-subtitle>
                        </v-list-item-content>
                      </v-list-item>
                    </template>
                  </v-card-text>
                </v-card>
              </div>

              <div class="shadow-col mt-4">
                <TitleSection
                  :title="labels.social[lang]"
                  noLogo
                  smallText
                  class="mb-3 fw-600 readable"
                ></TitleSection>

                <v-card elevation="0" class="h-100">
                  <ul class="social-list">
                    <li
                      v-for="(social, idx) in socialNetworks"
                      :key="idx + 65858556 + 1"
                    >
                      <a
                        :href="social.URLSITIO"
                        target="_blank"
                        :title="social.DESCRIPCION"
                        class="readable"
                      >
                        <img
                          width="40"
                          height="40"
                          :src="loadImg(social.URLICONO)"
                          :alt="social.DESCRIPCION"
                        />
                      </a>
                    </li>
                  </ul>
                  <p class="text-center text-medium fw-400 text-direction">
                    {{ park.DIRECCION }}
                  </p>
                </v-card>
              </div>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import { imgService } from "@/libs/media";
import { mapGetters } from "vuex";
import TitleSection from "@/components/titles/TitleSection";
export default {
  components: {
    TitleSection,
  },
  data() {
    return {
      labels: {
        history: {
          en: "History",
          es: "Historia",
        },
        information: {
          en: "Information",
          es: "Información",
        },
        contact: {
          en: "Contact",
          es: "Contacto",
        },
        social: {
          en: "Our social networks",
          es: "Nuestras redes sociales",
        },
      },
    };
  },
  computed: {
    ...mapGetters({
      lang: "getLanguage",
      park: "getCurrentParkByDomain",
      groups: "getGroupsParkInformation",
      groupsDetail: "getDetailGroupsParkInformation",
      socialNetworks: "getSocialsNetworkPark",
      accTools: "getAccesibilityTool"
    }),
  },
  mounted() {
    this.accTools.restart(this.lang)
  },
  methods: {
    loadImg(src) {
      return imgService(src);
    },
    getGroupsDetail(groupId) {
      return this.groupsDetail.filter(
        (g) => g.IDGRUPOINFORMATIVOPARQUE == groupId
      );
    },
    getContactList() {
      const list = [];
      if (this.park.TELEFONO1 || this.park.TELEFONO2) {
        list.push({
          URLFOTO: this.park.URLFOTOTELEFONO,
          DESCRIPCION: {
            en: "Telephone",
            es: "Teléfono",
          },
          DESCRIPCIONALARGA: `${this.park.TELEFONO1}\n${this.park.TELEFONO2}`,
          ITEMS: [this.park.TELEFONO1, this.park.TELEFONO2],
        });
      }
      if (this.park.CORREO1 || this.park.CORREO2) {
        list.push({
          URLFOTO: this.park.URLFOTOCORREO,
          DESCRIPCION: {
            en: "Email",
            es: "Teléfono",
          },
          DESCRIPCIONALARGA: `${this.park.CORREO1}\n${this.park.CORREO2}`,
          ITEMS: [this.park.CORREO1, this.park.CORREO2],
        });
      }

      if (this.park.MOVIL1 || this.park.MOVIL2) {
        list.push({
          URLFOTO: this.park.URLFOTOMOVIL,
          DESCRIPCION: {
            en: "Cell phone",
            es: "Celular",
          },
          DESCRIPCIONALARGA: `${this.park.MOVIL1}\n${this.park.MOVIL2}`,
          ITEMS: [this.park.MOVIL1, this.park.MOVIL2],
        });
      }
      return list;
    },
  },
};
</script>

<style lang="scss">
.title-pin-container {
  display: flex;
  flex-flow: column nowrap;
  color: #fff;
  .title-pin {
    font-size: 18px;
    font-weight: 600;
    @media screen and (max-width: 600px) {
      font-size: 16px;
    }
  }
  .subtitle-pin {
    font-size: 14px;
    font-weight: 500;
    color: var(--color-yellow);
    @media screen and (max-width: 600px) {
      font-size: 13.5px;
    }
  }
}
.shadow-col {
  box-shadow: -1px 4px 74px -16px rgb(0, 0, 0, 0.15) !important;
  padding: 25px;
}
.card-group-information {
  .item-content {
    margin-left: 40px;
  }
  .img-group {
    max-width: 48px;
  }
  .title-group {
    color: var(--color-turquoise);
  }
  .title-detail-group {
    font-size: 15px !important;
    font-weight: 600;
  }
}

.social-list {
  display: flex;
  list-style: none;
  gap: 7px;
  padding: 15px 0;
  justify-content: center;
  padding-left: 0;
  @media screen and (max-width: 600px) {
  }
  a {
    text-decoration: none;
    transition: all ease-in-out 0.25s;
    display: inline-flex;
    &:hover {
      margin-top: -5px;
    }
  }
}

.text-direction {
  font-size: 13px !important;
  @media screen and (max-width: 500px) {
    font-size: 12px !important;
  }
}
</style>